import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  NavLink,
} from "reactstrap";
import { Tooltip } from "@material-ui/core";
import Notifications from "./TabsContent/Notifications";
import { config } from "../../../config";
import { getCall } from "../../../Helpers/Api";
import { getClient } from "../../../Helpers/Client";
import { noDelaySetInterval } from "../../../Helpers/Time";
import { getUser } from "../../../Helpers/User";
import { getPermissions } from "../../../States/Permissions";
import { bottomAlert } from "../../../Components/Notifications/ToastAlerts";
import { withRouter } from "react-router";
import { TbReport, TbMail } from "react-icons/tb";
import { AiOutlineThunderbolt } from "react-icons/ai";
import Drawer from '@mui/material/Drawer';
import { AiOutlineMenu } from "react-icons/ai";

class HeaderDots extends React.Component {
  constructor(props) {
    super(props);
    // this.getUnreadNotifications = this.getUnreadNotifications.bind(this);
    this.state = {
      allowed_clients: [],
      active: false,
      count: 0,
      modal: false,
      notifications: [],
      disableButton: true,
      error: false,
      organizationName: "",
      current_organizationName: "",
      menuAnchor:false,
      client: {
      },
    };
    this.user = getUser();
    this.toggleMenu = this.toggleMenu.bind(this);
    this.getClientDetails = this.getClientDetails.bind(this);

  }

  // getUnreadNotifications() {
  //   getCall({
  //     url: config.url.NOTIFICATIONS,
  //     path: "/unread_notification",
  //     onSuccess: (response) => {
  //       this.setState({ count: response.data.count });
  //       this.setState({ notifications: response.data.records });
  //     },
  //     onFailure: (error) => {
  //       console.log(error);
  //     },
  //   });
  // }
  toggleMenu(event,open) {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({menuAnchor:open});
  };

  getYourAllOrg = () => {
    getCall({
      url: config.url.CLIENTS,
      path: `/allowed_clients`,
      onSuccess: (response) => {
        this.setState({ allowed_clients: response.data });
      },
      onFailure: (error) => {
        bottomAlert(
          "We are unable to fetch your organizations. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
  };

  getClientDetails(){
    getCall({
      url: config.url.CLIENTS,
      path: "/" + getClient().id,
      onSuccess: (response) => {
        this.setState({ client: response.data});
      },
      onFailure: (error) => {
        bottomAlert("Unable to load the clients. " + ((error.response && error.response.data.detail) || error.toString()), "warning");
      }
    });
  }

  componentDidMount() {
    this.getYourAllOrg();
    this.getClientDetails();
    // this.timer = noDelaySetInterval(this.getUnreadNotifications, 4000);
  }

  // componentWillUnmount() {
  //   clearInterval(this.timer);
  // }

  render() {
    const screen = window.matchMedia("(min-width: 600px)");
    var screen_max_400 = window.matchMedia("(max-width:400px)");
    var screen_max_600 = window.matchMedia("(max-width:600px)");
    var screen_max_768 = window.matchMedia("(max-width:768px)");
    var screen_max_900 = window.matchMedia("(max-width:900px)");

    return (
      <Fragment>
        <div
          className="header-dots"
          style={{ paddingTop: screen_max_600.matches ?5:0, gap: screen_max_400.matches ? 8 : screen_max_768.matches ? 15 : 20 }}
          id="all"
        >
          {screen_max_900.matches &&
            <div style={{position:"absolute",left:"10px"}}>
                <span style={{ fontSize: screen_max_600.matches ?"16px":"21px" }} onClick={(e)=>this.toggleMenu(e,true)}><AiOutlineMenu/></span>
              <Drawer
                anchor={"left"}
                open={this.state.menuAnchor}
                onClose={(e)=> this.toggleMenu(e,false)}
              >
                <div style={{minWidth:"200px"}}>
                  <Link to={"/workspace/conversations"} style={{ padding: "0" }}>
                    <div style={{display:"flex",placeItems:"center",gap:"15px",padding:"10px",fontSize:'22px'}}>
                      <i
                        className="lnr-users icon-gradient bg-secondary"
                      ></i>
                      <p className="text-secondary" style={{margin:"6px 0 0 0",lineHeight:"22px"}}>Leads</p>
                    </div>
                  </Link>
                {getPermissions()?.includes("outbound_agent")?(
                <></>):(
                  <Link to={"/assets"} style={{ padding: "0" }}>
                    <div style={{display:"flex",placeItems:"center",gap:"15px",padding:"10px",fontSize:'22px'}}>
                      <i
                        className="lnr-store icon-gradient bg-secondary"
                      ></i>
                      <p className="text-secondary" style={{margin:"6px 0 0 0",lineHeight:"22px"}}>Assets</p>
                    </div>
                  </Link>
                )}
                {getPermissions()?.includes("admin") && (
                  <Link to={"/workspace/reports"} style={{ padding: "0" }}>
                    <div style={{display:"flex",placeItems:"center",gap:"15px",padding:"10px",fontSize:'22px'}}>
                      <TbReport style={{verticalAlign:"sub",color:"#6c757d",strokeWidth:"1.5px"}} />
                      <p className="text-secondary" style={{margin:"6px 0 0 0",lineHeight:"22px"}}>Reports</p>
                    </div>
                  </Link>
                  )}  
                  {getPermissions()?.includes("outbound_agent")?(
                <></>
              ):(
                  
                  <Link to={"/workspace/strike"} style={{ padding: "0" }}>
                    <div style={{display:"flex",placeItems:"center",gap:"15px",padding:"10px",fontSize:'22px'}} className="mob beta">
                      <AiOutlineThunderbolt style={{verticalAlign:"sub",color:"#6c757d",strokeWidth:"1.5px"}} />
                      <p className="text-secondary" style={{margin:"6px 0 0 0",lineHeight:"22px"}}>Strike</p>
                    </div>
                  </Link>
              )}
              {getPermissions()?.includes("outbound_agent")?(
                <></>
              ):(
                  
                  <Link to={"/workspace/template"} style={{ padding: "0" }}>
                    <div style={{display:"flex",placeItems:"center",gap:"15px",padding:"10px",fontSize:'22px'}}>
                      <TbMail style={{verticalAlign:"sub",color:"#6c757d",strokeWidth:"1.5px"}} />
                      <p className="text-secondary" style={{margin:"6px 0 0 0",lineHeight:"22px"}}>Video Emails</p>
                    </div>
                  </Link>
              )}
                </div>
              </Drawer>
            </div>
          }
          <UncontrolledDropdown>
            <Tooltip title="Dashboard">
              <Link to={config.landing_page} style={{ padding: "0" }}>
                <DropdownToggle
                  style={{
                    display: "flex",
                    placeItems: "baseline",
                    gap: 4,
                    marginTop: screen_max_600.matches && "-2px"
                  }}
                  className="p-0"
                  color="link"
                >
                  <div className="language-icon" id="show_home">
                    <i
                      style={{ fontSize: screen_max_600.matches ?"20px":"25px" }}
                      className="lnr-home icon-gradient bg-secondary"
                    ></i>
                  </div>
                  {screen.matches && (
                    <div
                      style={{
                        display: "block",
                        fontSize: "14px",
                        // position: "absolute",
                      }}
                      className="text-secondary"
                    >
                      Home
                    </div>
                  )}
                </DropdownToggle>
              </Link>
            </Tooltip>
          </UncontrolledDropdown>
          {!screen_max_900.matches &&
            <>
              <UncontrolledDropdown>
                <Tooltip title="Leads">
                  <Link to={"/workspace/conversations"} style={{ padding: "0" }}>
                    <DropdownToggle
                      className="p-0"
                      style={{ display: "flex", placeItems: "baseline", gap: 4 }}
                      data-tut="reactour__leads"
                      color="link"
                    >
                      <div className="language-icon" id="show_leads">
                        <i
                          style={{ fontSize: screen_max_600.matches ?"20px":"25px" }}
                          className="lnr-users icon-gradient bg-secondary"
                        ></i>
                      </div>
                      {screen.matches && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "14px",
                            // position: "absolute",
                          }}
                          className="text-secondary"
                        >
                          Leads
                        </div>
                      )}
                    </DropdownToggle>
                  </Link>
                </Tooltip>
              </UncontrolledDropdown>
              {getPermissions()?.includes("outbound_agent")?(
                <></>):(
              <UncontrolledDropdown>
                <Tooltip title="Widgets, media, products and orders">
                  <Link to={"/assets"} style={{ padding: "0" }}>
                    <DropdownToggle
                      className="p-0"
                      style={{
                        display: "flex",
                        placeItems: "baseline",
                        gap: 4,
                        marginRight: 5,
                      }}
                      data-tut="reactour__assets"
                      color="link"
                    >
                      <div className="language-icon" id="show_assets">
                        <i
                          style={{ fontSize: screen_max_600.matches ?"20px":"25px" }}
                          className="lnr-store icon-gradient bg-secondary"
                        ></i>
                      </div>
                      {screen.matches && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "14px",
                            // position: "absolute",
                          }}
                          className="text-secondary"
                        >
                          Assets
                        </div>
                      )}
                    </DropdownToggle>
                  </Link>
                </Tooltip>
              </UncontrolledDropdown>
              )}
              {getPermissions()?.includes("admin") && (
              <UncontrolledDropdown>
                <Tooltip title="Widgets, media, products and orders">
                  <Link to={"/workspace/reports"} style={{ padding: "0" }}>
                    <DropdownToggle
                      className="p-0"
                      style={{
                        display: "flex",
                        placeItems: "baseline",
                        gap: 4,
                        marginRight: 5,
                        marginTop: screen_max_600.matches ? "1px":"2px"
                      }}
                      data-tut="reactour__assets"
                      color="link"
                    >
                      <div className="language-icon" id="show_assets">
                        {/* <i
                          style={{ fontSize: screen_max_600.matches ?"20px":"25px" }}
                          className="lnr-store icon-gradient bg-secondary"
                        ></i> */}
                        <TbReport style={{ fontSize: screen_max_600.matches ?"20px":"25px",verticalAlign:"sub",color:"#6c757d",strokeWidth:"1.5px" }}/>
                      </div>
                      {screen.matches && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "14px",
                            // position: "absolute",
                          }}
                          className="text-secondary"
                        >
                          Reports
                        </div>
                      )}
                    </DropdownToggle>
                  </Link>
                </Tooltip>
              </UncontrolledDropdown>
              )} 
              {getPermissions()?.includes("outbound_agent") ?(
                <></>):(
                  this.state.client.strike_enabled && (
                    <UncontrolledDropdown className="beta">
                      <Tooltip title="Live User Monitoring">
                        <Link to={"/workspace/strike"} style={{ padding: "0" }}>
                          <DropdownToggle
                            className="p-0"
                            style={{
                              display: "flex",
                              placeItems: "baseline",
                              gap: 4,
                              marginRight: 5,
                              marginTop: screen_max_600.matches ? "1px":"2px"
                            }}
                            data-tut="reactour__assets"
                            color="link"
                          >
                            <div className="language-icon" id="show_assets">
                              <AiOutlineThunderbolt style={{ fontSize: screen_max_600.matches ?"20px":"25px",verticalAlign:"sub",color:"#6c757d",strokeWidth:"1.5px" }}/>
                            </div>
                            {screen.matches && (
                              <div
                                style={{
                                  display: "block",
                                  fontSize: "14px",
                                  // position: "absolute",
                                }}
                                className="text-secondary"
                              >
                                Strike
                              </div>
                            )}
                          </DropdownToggle>
                        </Link>
                      </Tooltip>
                    </UncontrolledDropdown>
                  )
              )}
              {getPermissions()?.includes("outbound_agent") ?(
                <></>):(
              <UncontrolledDropdown>
                <Tooltip title="Send video email">
                  <Link to={"/workspace/template"} style={{ padding: "0" }}>
                    <DropdownToggle
                      className="p-0"
                      style={{
                        display: "flex",
                        placeItems: "baseline",
                        gap: 4,
                        marginRight: 5,
                        marginTop: screen_max_600.matches ? "1px":"2px"
                      }}
                      data-tut="reactour__assets"
                      color="link"
                    >
                      <div className="language-icon" id="show_assets">
                        <TbMail style={{ fontSize: screen_max_600.matches ?"20px":"25px",verticalAlign:"sub",color:"#6c757d",strokeWidth:"1.5px" }}/>
                      </div>
                      {screen.matches && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "14px",
                            // position: "absolute",
                          }}
                          className="text-secondary"
                        >
                          Video Emails
                        </div>
                      )}
                    </DropdownToggle>
                  </Link>
                </Tooltip>
              </UncontrolledDropdown>
              )}
            </>
          }  
          {/* <UncontrolledDropdown>
            <Tooltip title="Notifications">
              <DropdownToggle className="p-0" color="link">
                <div
                  className="icon-wrapper icon-wrapper-alt"
                  id="show_notifications"
                >
                  <div
                    className={
                      this.state.count > 0 ? "bg-danger" : "bg-secondary"
                    }
                  />
                  <IoIosNotificationsOutline
                    className={
                      this.state.count > 0 ? "text-danger" : "text-secondary"
                    }
                    color="#d92550"
                    fontSize="25px"
                    fontWeight={600}
                  ></IoIosNotificationsOutline>
                  <span
                    className={
                      "Notifications badge rounded-circle " +
                      (this.state.count > 0 ? "bg-danger" : "bg-secondary")
                    }
                    style={{
                      position: "absolute",
                      top: "5px",
                      border: "1px solid #fff",
                      borderRadius: "50%",
                      right: "8px",
                      fontSize: "8px",
                      background: "#f44336",
                      textAlign: "center",
                      color: "white",
                      padding: "3px",
                      height: "16px",
                      linehe: "19px",
                      display: "block",
                    }}
                  >
                    {this.state.count}
                  </span>
                </div> */}
          {/* <label style={{display: "block", fontSize: "10px"}}>Alerts</label> */}
          {/* </DropdownToggle>
            </Tooltip>
            <DropdownMenu left="true" className="dropdown-menu-xl rm-pointers">
              <div className="dropdown-menu-header mb-0">
                <div className="dropdown-menu-header-inner bg-deep-blue">
                  <div
                    className="menu-header-image opacity-1"
                    style={{
                      backgroundImage: "url(" + ")",
                    }}
                  />
                  <div className="menu-header-content text-dark">
                    <h5 className="menu-header-title">Notifications</h5>
                    <h6 className="menu-header-subtitle">
                      You have <b>{this.state.count}</b> unread messages
                    </h6>
                  </div>
                </div>
              </div>
              <Notifications notifications={this.state.notifications} /> */}
          {/* {console.log(this.state.notifications)} */}
          {/* <Nav vertical>
                <NavItem className="nav-item-divider" />
                <NavItem className="nav-item-btn text-center">
                  <Link to="/history/notifications">
                    <Button
                      size="sm"
                      className="btn-shadow btn-wide btn-pill"
                      color="focus"
                    >
                      View all notifications
                    </Button>
                  </Link>
                </NavItem>
              </Nav>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(HeaderDots);
