import React from "react";
import { bottomAlert } from "../../Notifications/ToastAlerts";
import { putCall } from "../../../Helpers/Api";
import { config } from "../../../config";
import { getUser } from "../../../Helpers/User";
import { setUser } from "../../../States/User";
import { setClient } from "../../../States/Client";
import { getActiveClient } from "../../../Helpers/Plan";
import { Button, Card, CardContent, CardHeader, Grid, TextField } from "@material-ui/core";

class BasicDetails extends React.Component {
  constructor(props) {
    super(props);
    const user = getUser();
    const client = getActiveClient();

    if (
      client.name.includes(`${user.user_name.split('@')[0]}`) &&
      `${user.user_name.split('@')[0]}`.length + 6 === client.name.length
    ) {
      client.name = ""; 
    }

    this.state = {
      ...user,
      name: client.name,
      clientId: client.id,
      isEditable: {
        display_name: !user.display_name, // editable if no display_name
        email: !user.email, // editable if no email
        mobile_number: !user.mobile_number, // editable if no mobile number
        name: !client.name, // editable if no organization name
      },
      display_nameError: !user.display_name.trim().length, // Initialize error state
      emailError: !user.email.trim().length, // Initialize error state
      mobile_numberError: !user.mobile_number.trim().length, // Initialize error state
      nameError: !client.name.trim().length, // Initialize error state
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);
  }

  handleValidation = () => {
    const mobile_numberError = !this.state.mobile_number.trim().length || this.state.mobile_number.length > 10;
    this.setState({
      display_nameError: !this.state.display_name.trim().length,
      emailError: !this.state.email.trim().length,
      mobile_numberError,
      nameError: !this.state.name.trim().length,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile_number') {
      if (value.length <= 10) {
        this.setState({ [name]: value }, () => {
          this.handleValidation();
        });
      }
    } else {
      this.setState({ [name]: value }, () => {
        if (name === 'display_name' || name === 'email') {
          this.handleValidation();
        }
      });
    }
  };

  handleOrgChange = (e) => {
    this.setState({ name: e.target.value }, () => {
      this.handleValidation();
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.handleValidation(); // Validate fields before submitting

    if (this.state.display_nameError || this.state.emailError || this.state.mobile_numberError || this.state.nameError) {
      bottomAlert("Please fill out all required fields.", "warning");
      return;
    }

    // Submit profile details
    putCall({
      url: config.url.USERS,
      path: this.state.id,
      data: this.state,
      onSuccess: (response) => {
        bottomAlert("Profile details saved.", "success");
        setUser(this.state);
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      },
      onFailure: (error) => {
        bottomAlert(
          "Your request could not be processed. " +
            ((error.response && error.response.data.detail) || error.toString()),
          "warning"
        );
      },
    });

    // Submit organization details
    putCall({
      url: config.url.CLIENTS,
      path: this.state.clientId,
      data: { name: this.state.name },
      onSuccess: (response) => {
        bottomAlert("Organization name changed", "success");
        setClient({ ...this.state, name: this.state.name });
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to complete your request. " +
            ((error.response && error.response.data.detail) || error.toString()),
          "warning"
        );
      },
    });
  };

  render() {
    return (
      <Card
        sx={{
          maxWidth: 550,
          margin: "0 auto",
          borderRadius: 3,
          boxShadow: 3,
          mt: 4,
          p: 2,
          bgcolor: "#f7f7f7"
        }}
      >
        <CardHeader
        className="bg-deep-blue menu-header-title"
          title="Profile Information"
          titleTypographyProps={{ variant: 'h4', align: 'center' }}
          sx={{
            bgcolor: "linear-gradient(135deg, #007bff, #6610f2)",
            color: "#fff",
            borderRadius: "12px 12px 0 0",
            textAlign: "center",
            py: 3
          }}
        />
        <CardContent>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Display Name"
                  variant="outlined"
                  name="display_name"
                  value={this.state.display_name}
                  onChange={this.handleChange}
                  error={this.state.display_nameError}
                  helperText={this.state.display_nameError ? 'Display name is required' : ''}
                  placeholder="Enter display name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  disabled={!this.state.isEditable.email}
                  error={this.state.emailError}
                  helperText={this.state.emailError ? 'Email is required' : ''}
                  placeholder="Enter your email"
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  fullWidth
                  label="Mobile"
                  variant="outlined"
                  name="mobile_number"
                  type="number"
                  value={this.state.mobile_number}
                  onChange={this.handleChange}
                  disabled={!this.state.isEditable.mobile_number}
                  error={this.state.mobile_numberError}
                  helperText={this.state.mobile_numberError ? (this.state.mobile_number.length > 10 ? 'Mobile number cannot exceed 10 digits' : 'Mobile number is required') : ''}
                  placeholder="Enter mobile number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Organization Name"
                  variant="outlined"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleOrgChange}
                  disabled={!this.state.isEditable.name}
                  error={this.state.nameError}
                  helperText={this.state.nameError ? 'Organization name is required' : ''}
                  placeholder="Enter organization name"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: '#3ac47d', color: 'white' }}
                >
                  <i className="fas fa-save" style={{ marginRight: '8px' }}></i>
                  Save Profile & Organization
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    );
  }
}

export default BasicDetails;
