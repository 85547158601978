import { Modal } from "reactstrap";
import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Profile from "./../BoardingForm/Profile/Profile";
import Organization from "../BoardingForm/Organization/Organization";
import Source from "../BoardingForm/Source-Intend/SourceIntend";
import { getUser } from "../../Helpers/User";
import { getActiveClient } from "../../Helpers/Plan";
import { getCall } from "../../Helpers/Api";
import { config } from "../../config";
import BasicDetails from "../BoardingForm/ProfilePopUp/BasicDetails";


const styles = (theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    height: "15px"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

const getSteps = (user, activeClient, needSource) => {
  const forms = [];

  // Push BasicDetails form if any of the user details are missing
  if (user.id) {
    if (user.display_name === "") forms.push(BasicDetails);
    if (user.email === "") forms.push(BasicDetails);
    if (user.mobile_number === "") forms.push(BasicDetails);
  }

  // Push BasicDetails form if organization details are missing
  if (activeClient.id && activeClient.name === "") {
    forms.push(BasicDetails);
  }

  // Push the Source form if necessary
  if (needSource) {
    forms.push(Source);
  }

  // Return the list of forms
  return forms;
};


class HorizontalLinearStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: getUser(),
      activeClient: getActiveClient(),
      nextButtonDisable: true,
      activeStep: 0,
      skipped: new Set(),
      modal: true,
      clientCreated: props.clientCreated,
      steps: []
    };
    
    this.handleNextButtonDisable = this.handleNextButtonDisable.bind(this);
    this.isStepSkipped = this.isStepSkipped.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleDisable = this.handleDisable.bind(this);
  }

  componentDidMount() {
    getCall({
      url: config.url.SOURCES,
      path: "/",
      onSuccess: (response) => {
        if (this.state.activeStep === this.state.steps.length - 1) {
          this.setState({ modal: false });
        } else {
          this.state.steps = getSteps(this.state.user, this.state.activeClient, false);
        }
      },
      onFailure: (error) => {
        this.state.steps = getSteps(this.state.user, this.state.activeClient, false);
      }
    });
  }

  handleNextButtonDisable = () => {
    this.setState({ nextButtonDisable: false });
  };

  isStepSkipped = (step) => {
    return this.state.skipped.has(step);
  };

  handleNext = () => {
    if (this.state.activeStep === this.state.steps.length - 1) {
      this.setState({ modal: false });
      return;
    }

    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleSkip = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleDisable = () => {
    if (this.state.steps.length === 0) {
      return true;
    } else {
      return false
    }
  }

  render() {
    const { classes } = this.props;
    const DisplayForm = this.state.activeStep < this.state.steps.length ? this.state.steps[this.state.activeStep] : null;
    return (
      <>
        {
          this.state.steps.length ?
          <Modal
            isOpen={this.state.modal}
            className="custom-dialog-1"
            contentClassName="custom-content"
            modalClassName="modal-custom"
            wrapClassName="custom-dialog"
          >
            <div style={{textAlign: "center"}} className={classes.root}>
              
              <div>
                {this.state.activeStep === this.state.steps.length ? null : (
                  <div style={{ padding: "20px" }}>
                    <Typography
                      component={"span"}
                      variant={"body2"}
                      className={classes.instructions}
                    >
                      <DisplayForm onSuccess={this.handleNext}/>
                    </Typography>
                    {/* <div>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={this.state.activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        {"<"}
                  </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={this.state.activeStep === this.state.steps.length - 1}
                        onClick={this.handleSkip}
                        className={classes.button}
                      >
                        {">"}
                  </Button>
                     
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          </Modal>
          : null
        }
      </>
    );
  }
}
export default withStyles(styles, { withTheme: true })(HorizontalLinearStepper);
